import { gql } from '@apollo/client';

export const GET_VEHICLE_LIST = gql`
query ($filter: VehicleFilter) {
    getVehicles(filter: $filter) {
        ... on Vehicle {
        id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        range
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        addressId
        isUsedForRental
        vehicleModelId
        isBle
        batteryType
        vehicleIconType
        isAssign
        isFaulted
        isMissing
        isInMaintenance
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;
export const GET_VEHICLE_DETAILS = gql`
query ($id: String!) {
    getVehicle(id: $id) {
        ... on Vehicle {
        id
        type
        qrNumber
        nestId
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        range
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        iotCommands
        lastSpeedLimit
        addressId
        isUsedForRental
        vehicleModelId
        isBle
        onGoingRide
        activeOrder
        batteryType
        vehicleIconType
        chassisNumber
        isFaulted
        isMissing
        isInMaintenance
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;
export const GET_VEHICLE_COUNT = gql`
query ($filter: VehicleFilter) {
    getCount(filter: $filter) {
        ...on VehicleCount {
            count
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;

export const UPDATE_VEHICLE = gql`
mutation ($id: String!, $data: VehicleInput!) {
    updateVehicle(id: $id, data: $data) {
        ... on Vehicle {
            id
            type
            qrNumber
            nestId 
            numberPlate
            modelName
            image
            manufacturer
            name
            imei
            lockManufacturer
            isActive
            isDeleted
            registerId
            batteryLevel
            iotBatteryLevel
            model
            lastUsed
            currentLocation
            isAvailable
            isRideCompleted
            chargeStatus
            gsm
            iotCode
            vehicleCode
            iotBuildTime
            iotVersion
            scooterVersion
            ecuHardwareVersion
            ecuSoftwareVersion
            power1
            power2
            speed
            iccid
            mac
            acceleratorResponse
            headLight
            mode
            tailLightTwinkling
            bleKey
            connectionStatus
            lastConnectedDateTime
            lastConnectionCheckDateTime
            locationUpdatedAt
            isLocationChanged
            lastLocationChanged
            isVehicleOutsideZone
            lockStatus
            bleCommandPassword
            networkSignal
            accelerometerSensitivity
            pingInterval
            ridePingInterval
            maxSpeedLimit
            markedAs
            lastAlarmed
            lastSpeedSet
            isTaskCreated
            batteryLockStatus
            wheelLockStatus
            cableLockStatus
            vehicleRideSummary
            franchiseeId
            franchiseeRentStartDate
            dealerRentStartDate
            dealerLastRentPaymentDate
            franchiseeLastRentPaymentDate
            dealerId
            fleetType
            lastSpeedLimit
            isUsedForRental
            vehicleModelId
            addressId
            isBle
            cityId
            batteryType
            vehicleIconType
            chassisNumber
            isMissing
            isInMaintenance
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;
export const ADD_VEHICLE = gql`
mutation ($data: VehicleInput!) {
    addVehicle(data: $data) {
        ... on Vehicle {
            id
            type
            qrNumber
            nestId 
            numberPlate
            modelName
            image
            manufacturer
            name
            imei
            lockManufacturer
            isActive
            isDeleted
            registerId
            batteryLevel
            iotBatteryLevel
            model
            lastUsed
            currentLocation
            isAvailable
            isRideCompleted
            chargeStatus
            gsm
            iotCode
            vehicleCode
            iotBuildTime
            iotVersion
            scooterVersion
            ecuHardwareVersion
            ecuSoftwareVersion
            power1
            power2
            speed
            iccid
            mac
            acceleratorResponse
            headLight
            mode
            tailLightTwinkling
            bleKey
            connectionStatus
            lastConnectedDateTime
            lastConnectionCheckDateTime
            locationUpdatedAt
            isLocationChanged
            lastLocationChanged
            isVehicleOutsideZone
            lockStatus
            bleCommandPassword
            networkSignal
            accelerometerSensitivity
            pingInterval
            ridePingInterval
            maxSpeedLimit
            markedAs
            lastAlarmed
            lastSpeedSet
            isTaskCreated
            batteryLockStatus
            wheelLockStatus
            cableLockStatus
            vehicleRideSummary
            franchiseeId
            franchiseeRentStartDate
            dealerRentStartDate
            dealerLastRentPaymentDate
            franchiseeLastRentPaymentDate
            dealerId
            fleetType
            lastSpeedLimit
            isUsedForRental
            vehicleModelId
            addressId
            isBle
            cityId
            batteryType
            vehicleIconType
            isMissing
            isInMaintenance
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;

export const UPDATE_VEHICLE_SUBSCRIPTION = gql`
subscription($id:String!){
	changeVehicle(id: $id){
		id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        batteryType
        vehicleIconType
        isMissing
        isInMaintenance
	}
}
`
export const ADD_VEHICLE_SUBSCRIPTION = gql`
subscription($filter: VehicleFilter){
	addVehicleSubscription(filter: $filter){
		id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        batteryType
        vehicleIconType
        isMissing
        isInMaintenance
	}
}
`
export const ASSIGN_VEHICLE_SUBSCRIPTION = gql`
subscription($filter: VehicleFilter){
	assignVehicleSub(filter: $filter){
		id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        batteryType
        vehicleIconType
        isMissing
        isInMaintenance
	}
}
`
export const RETAIN_VEHICLE_SUBSCRIPTION = gql`
subscription($filter: VehicleFilter){
	retainVehicleSub(filter: $filter){
		id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        batteryType
        vehicleIconType
        isMissing
        isInMaintenance
	}
}
`
export const GET_IOT_COMMAND_CALLBACK = gql`
query($filter: IOTCommandFilter){
	getIOTCommandsCallback(filter: $filter){
        ... on IOTCommandCallbackTrack {
        imei
        logType
        commandName
        sentCommand
        actualCallback
        decodedCallback
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
	}
}
`
export const GET_IOT_COMMAND_CALLBACK_COUNT = gql`
query ($imei: String!) {
    getIOTCommandsCallbackCount(imei: $imei) {
        ...on IOTCommandCount {
            count
        }
        ... on ErrorResponse {
            errors {
                code
                message
            }
        }
    }
}`;
export const IOT_COMMAND_SUBSCRIPTION = gql`
subscription($imei:String!){
	addIotCallbackSub(imei: $imei){
        imei
            logType
            commandName
            sentCommand
            actualCallback
            decodedCallback
	}
}
`
// GET_DASHBOARD_VEHICLE_LIST
export const GET_DASHBOARD_VEHICLE_LIST = gql`
query($filter :VehicleDashboardFilter) {
    getDashBoardVehicles(filter:  $filter){
        id
        type
        qrNumber
        nestId 
        numberPlate
        modelName
        image
        manufacturer
        name
        imei
        lockManufacturer
        isActive
        isDeleted
        registerId
        batteryLevel
        range
        iotBatteryLevel
        model
        lastUsed
        currentLocation
        isAvailable
        isRideCompleted
        chargeStatus
        gsm
        iotCode
        vehicleCode
        iotBuildTime
        iotVersion
        scooterVersion
        ecuHardwareVersion
        ecuSoftwareVersion
        power1
        power2
        speed
        iccid
        mac
        acceleratorResponse
        headLight
        mode
        tailLightTwinkling
        bleKey
        connectionStatus
        lastConnectedDateTime
        lastConnectionCheckDateTime
        locationUpdatedAt
        isLocationChanged
        lastLocationChanged
        isVehicleOutsideZone
        lockStatus
        bleCommandPassword
        networkSignal
        accelerometerSensitivity
        pingInterval
        ridePingInterval
        maxSpeedLimit
        markedAs
        lastAlarmed
        lastSpeedSet
        isTaskCreated
        batteryLockStatus
        wheelLockStatus
        cableLockStatus
        vehicleRideSummary
        franchiseeId
        franchiseeRentStartDate
        dealerRentStartDate
        dealerLastRentPaymentDate
        franchiseeLastRentPaymentDate
        dealerId
        fleetType
        lastSpeedLimit
        batteryType
        vehicleIconType
        isMissing
        isInMaintenance

    }
}`;

export const GET_NOTIFICATION_COUNT = gql`
query ($filter: NotificationFilter) {
    getNotificationCount(filter: $filter) {
            count
            pnCount
    }
}`;
